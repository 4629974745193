<template>
	<el-dialog @open="handleOpen" :before-close="handleClose" :visible.sync="bgReplaceDialogOpen" width="850px" title="图片详情" style="margin-top: -10vh">
		<div class="bg-result-container">
			<el-image class="bg-result-left" fit="contain" :src="bigViewImg"></el-image>
			<div class="bg-result-right">
				<div class="title" style="margin-top: 0">商品主图</div>
				<el-image
					class="min-img"
					v-if="drawRecord.bgImg"
					:src="drawRecord.maskImg"
					fit="contain"
					@mouseenter="changImgView(drawRecord.maskImg)"
					@mouseleave="changImgView(drawRecord.generateImg)"
				></el-image>
				<div class="title">背景参考图</div>
				<el-image
					class="min-img"
					v-if="drawRecord.bgImg"
					:src="drawRecord.bgImg"
					fit="contain"
					@mouseenter="changImgView(drawRecord.bgImg)"
					@mouseleave="changImgView(drawRecord.generateImg)"
				></el-image>
				<div class="title">画页比例</div>
				<div>尺寸：{{ drawRecord.imgWidth }}px*{{ drawRecord.imgHeight }}px</div>
				<div class="title">生成时间</div>
				<div>{{ drawRecord.createTime }}</div>
				<div class="btn">
					<el-button type="primary" style="width: 100%" @click="downLoadResultImg(drawRecord.generateImg)">下载</el-button>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import axios from 'axios';
import { getGoodsById } from '@/utils/api';
export default {
	data() {
		return {
			drawRecord: {
			},
			bigViewImg: ''
		};
	},
	props: {
		bgReplaceDialogOpen: false,
		goodsImgId: 0
	},
	mounted() {},
	methods: {
		downLoadResultImg(imgUrl) {
			const x = new window.XMLHttpRequest();
			x.open('GET', imgUrl, true);
			x.responseType = 'blob';
			x.onload = () => {
				const url = window.URL.createObjectURL(x.response);
				const a = document.createElement('a');
				a.href = url;
				a.download = imgUrl.substring(imgUrl.lastIndexOf('/') + 1);
				a.click();
			};
			x.send();
		},
		changImgView(imgUrl) {
			this.bigViewImg = imgUrl;
		},
		handleOpen() {
			var that = this;
			this.$nextTick(() => {
				getGoodsById(this.goodsImgId).then((res) => {
					console.log('==getGoodsById==' + JSON.stringify(res));
					that.drawRecord = res.data;
					that.bigViewImg = res.data.generateImg;
				});
			});
		},
		handleClose() {
			this.$emit('update:bgReplaceDialogOpen', false);
		}
	}
};
</script>

<style lang="less" scoped>
.bg-result-container {
	height: 650px;
	display: flex;
	.bg-result-left {
		width: 650px;
		height: 650px;
		border-radius: 10px;
	}
	.bg-result-right {
		display: block;
		margin-left: 10px;
		width: 150px;
		text-align: left;
		.title {
			color: #999999;
			margin-top: 20px;
			margin-bottom: 10px;
		}
		.min-img {
			width: 100px;
			height: 100px;
			border-radius: 5px;
		}
		.btn {
			margin: 20px 0px 20px 0px;
		}
	}
}
</style>