import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count: 100,
    num: 32.213123
  },
  getters: {
    getTofixed (state) {
      let num = JSON.parse(state.num)
      return num.toFixed(2)
    }
  },
  mutations: {
    ADD (state) {
      state.count += 1
    }
  },
  actions: {
    // increment (context) {
    //   context.commit('ADD')
    // }
    increment ({ commit, state }) {
      commit('ADD')
    }
  },
  modules: {
  }
})
