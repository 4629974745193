import axios from 'axios'
import Cookies from 'js-cookie';
import {
	Message
} from 'element-ui';


axios.defaults.baseURL = process.env.VUE_APP_API_URL


const service = axios.create({
	timeout: 120000,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json; charset=UTF-8'
	},
})
service.interceptors.request.use(
	config => {
		const token = Cookies.get('token');
		if (token) {
			config.headers['Authorization'] = token;
		}
		return config
	},
	error => {
		Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		console.log("res===" + JSON.stringify(res))
		//内置滑块验证码，直接返回
		if (res.repCode) {
			return res;
		}
		if (res.code === 200) {
			return res;
		}
		// A00004 未授权
		if (res.code === 401) {
			Message.error('登录已过期，请重新登录');
			setTimeout(() => {

			}, 1000);
		}
		if (res.code !== 200) {
			var errMsg = String(res.msg || "服务器出了点小差");
			Message.error(errMsg);
			return;
		}
		return res
	},
	error => {}
)
export default service