<template>
	<div>
		<header class="header">
			<div class="container">
				<div class="logo-nav">
					<img @click="goHome()" style="cursor: pointer" src="@/assets/logo-big.png" alt="logo" class="logo" />
					<nav class="nav">
						<ul>
							<li><a href="/">首页</a></li>
							<li><a href="/#/About">关于我们</a></li>
						</ul>
					</nav>
					<div class="login">
						<el-button v-if="!loginFlag" type="primary" @click="openLoginDialog()">登录</el-button>
						<el-button v-else type="primary" @click="toDashboard()">进入控制台</el-button>
					</div>
				</div>
			</div>
		</header>

		<div class="body">
			<el-image src="https://oss.djsmart.vip/app/home06.jpg" fit="scale-down" />
			<div class="hero">
				<div class="title1">AI图像大师</div>
				<div class="title2">一款高效便捷的智能图片处理工具</div>
				<button class="main-btn" @click="experienceNow('/Dashboard')">开始免费体验</button>
			</div>
			<div class="line-box">
				<div class="line-box-left">
					<div id="bottomImg" class="bottomImg" :style="{ height: imgHeigth, width: imgWidth, backgroundImage: 'url(' + bottomImg + ')' }">
						<div v-if="upperImg" class="upperImg" :style="{ backgroundImage: 'url(' + upperImg + ')', width: 100 - upperImgWidth + '%' }"></div>
						<div v-else class="upperUndefined" :style="{ width: 100 - upperImgWidth + '%' }">
							<span class="undefinedSpan">暂无结果</span>
						</div>
						<!-- 这是对外展示的滑块样式，仅仅是展示样式的，不然原生的样式不好看 -->
						<span class="spanHandle" :style="{ left: 'calc(' + upperImgWidth + '% - 24px)' }"></span>
						<!--滑块控制上层图片的宽度 -->
						<input class="inputRange" type="range" v-model="upperImgWidth" min="0" max="100" />
					</div>
				</div>
				<div class="line-box-right">
					<div class="desc-title">智能抠图</div>
					<div class="desc-content">全品类智能识别技术，实现一键自动精准抠图，轻松提取图像中的主体内容，为用户带来高效便捷的图像处理体验。</div>
					<el-button type="primary" style="margin-top: 20px; width: 190px; height: 50px" @click="experienceNow('/Dashboard/AIMatting')">免费体验</el-button>
				</div>
			</div>
			<div class="replace-bg-box">
				<div class="box-left">
					<div class="desc-title">背景一键替换</div>
					<div class="desc-content">
						只需轻轻一点，即可在瞬间完成背景的智能替换，不仅节省了大量的时间和精力，更让您的商品展示瞬间焕然一新，大幅提升工作效率，让您的商品展示始终保持前沿风采。
					</div>
					<el-button type="primary" style="margin-top: 20px; width: 190px; height: 50px" @click="experienceNow('/Dashboard/GoodsReplace')">免费体验</el-button>
					<el-image class="goods-src-img" fit="cover" src="https://oss.djsmart.vip/app/s001.jpg"></el-image>
				</div>
				
				<div class="bg-box-right">
					<div class="carousel-inner" :style="innerStyle">
						<div class="carousel-item" v-for="(item, index) in items" :key="index">
							<img style="border-radius: 10px" :src="item.src" :alt="item.alt" />
						</div>
					</div>
					<div class="carousel-thumbnails">
						<div class="thumbnail-item" v-for="(item, index) in items" :key="index" :class="{ active: currentIndex === index }" @click="selectBgItem(index)">
							<el-image fit="cover" style="width: 100px; height: 100px; border-radius: 5px" :src="item.src" :alt="item.alt" class="thumbnail-image" />
						</div>
					</div>
				</div>
			</div>

			<div class="inspiration-box">
				<div class="inspiration">
					<h2 class="inspiration-title">涂鸦作画</h2>
					<div class="inspiration-desc">每一个细微的想法都能迅速转化为实实在在的创意，让思维的种子在行动的土壤中即时生根发芽，展现出其质朴而动人的魅力。</div>
					<div class="inspiration-content">
						<div class="inspiration-content-div">
							<el-carousel height="500px" :interval="3000">
								<el-carousel-item class="inspiration-content-item" v-for="item in inspirations1" :key="item">
									<el-image style="border-radius: 10px;" fit="scale-down" :src="item" />
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="inspiration-content-div">
							<el-carousel height="500px" :interval="2500">
								<el-carousel-item class="inspiration-content-item" v-for="item in inspirations2" :key="item">
									<el-image style="border-radius: 10px;" fit="scale-down" :src="item" />
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="inspiration-content-div">
							<el-carousel height="500px" :interval="3500">
								<el-carousel-item class="inspiration-content-item" v-for="item in inspirations3" :key="item">
									<el-image style="border-radius: 10px;" fit="scale-down" :src="item" />
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</div>
				<button class="main-btn" style="margin-top: 20px;" @click="experienceNow('/Dashboard/Draw')">开始免费体验</button>
			</div>
			
		</div>

		<footer class="footer">
			<div class="container">
				<p>
					©2024 版权所有 东莞市洞见智能科技有限公司
					<a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2024313776号</a>
					|
					<a href="https://beian.mps.gov.cn" target="_blank">粤公网安备44195002000114号</a>
					| 联系邮箱:dj-smart@qq.com
				</p>
			</div>
		</footer>
		<LoginDialog :dialogVisible.sync="dialogOpen" />
	</div>
</template>

<script>
import Cookies from 'js-cookie';
import LoginDialog from '@/components/LoginDialog';
import { loginUserInfo } from '@/utils/api';
export default {
	components: {
		LoginDialog
	},
	mounted() {
		this.loadUserInfo();
		this.getImgSize();
		this.startAutoPlay(); // 组件挂载后开始自动播放
	},
	beforeDestroy() {
		this.stopAutoPlay(); // 组件销毁前清除定时器
	},
	computed: {
		innerStyle() {
			return {
				transform: `translateX(-${this.currentIndex * 100}%)`
			};
		}
	},
	data() {
		return {
			dialogOpen: false,
			loginFlag: false,
			inspirations1: ['https://oss.djsmart.vip/app/1008/a1.jpg', 'https://oss.djsmart.vip/app/1008/a2.jpg'],
			inspirations2: ['https://oss.djsmart.vip/app/1008/b1.jpg', 'https://oss.djsmart.vip/app/1008/b2.jpg'],
			inspirations3: ['https://oss.djsmart.vip/app/1008/c1.jpg', 'https://oss.djsmart.vip/app/1008/c2.jpg'],
			imgHeigth: '0px', // 图片高度
			imgWidth: '0px', // 图片宽度
			bottomImg: 'https://oss.djsmart.vip/app/mc.png', // 底图
			upperImg: 'https://oss.djsmart.vip/app/ma.jpg', // 上层图
			upperImgWidth: 50, // 上层图宽度
			bottomLabel: '', // 底图标签
			upperLabel: '',
			currentIndex: 0, // 当前显示的图片索引
			items: [
				// 走马灯项目数组
				{ src: 'https://oss.djsmart.vip/app/g005.png', alt: 'AI图像大师' },
				{ src: 'https://oss.djsmart.vip/app/g004.png', alt: 'AI图像大师' },
				{ src: 'https://oss.djsmart.vip/app/g003.png', alt: 'AI图像大师' },
				{ src: 'https://oss.djsmart.vip/app/g002.png', alt: 'AI图像大师' },
				{ src: 'https://oss.djsmart.vip/app/g001.png', alt: 'AI图像大师' }
				// ...更多图片
			],
			intervalId: null // 定时器ID
		};
	},
	methods: {
		selectBgItem(index){
			this.currentIndex = index;
			if(this.intervalId){
				this.intervalId = null;
			}
		},
		startAutoPlay() {
			this.intervalId = setInterval(() => {
				this.next();
			}, 3000); // 每3秒切换一次
		},
		stopAutoPlay() {
			clearInterval(this.intervalId);
		},
		next() {
			this.currentIndex = (this.currentIndex + 1) % this.items.length;
		},
		getImgSize() {
			var image = new Image();
			image.onload = () => {
				this.imgHeigth = image.height + 'px';
				this.imgWidth = image.width + 'px';
			};
			image.src = this.bottomImg;
		},
		experienceNow(url) {
			if (this.loginFlag) {
				this.$router.push({ path: url });
			} else {
				this.dialogOpen = true;
			}
		},
		goHome() {
			window.open('/', '_self', '', true);
		},
		toDashboard() {
			this.$router.push({ path: '/Dashboard' });
		},
		loadUserInfo() {
			var token = Cookies.get('token');
			if (!token || !token.trim()) {
				return;
			}
			loginUserInfo().then((res) => {
				this.loginFlag = res.data !== null;
			});
		},
		openLoginDialog() {
			this.dialogOpen = true;
		}
	}
};
</script>

<style lang="less">
@import '@/views/Home.less';
</style>
