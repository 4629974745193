<template>
	<div class="main-content">
		<el-table border :data="tableData">
			<el-table-column type="index" label="序号" width="50" header-align="center" align="center"></el-table-column>
			<el-table-column prop="sourceImg" label="原图" width="200" header-align="center" align="center">
				<template v-slot="scope">
					<el-image style="width: 100px; height: 100px" :src="scope.row.sourceImg" fit="scale-down" class="image-column" />
				</template>
			</el-table-column>
			<el-table-column prop="generateImg" label="成品图" width="200" header-align="center" align="center">
				<template v-slot="scope">
					<el-image style="width: 100px; height: 100px" :src="scope.row.generateImg" fit="scale-down" class="image-column" />
				</template>
			</el-table-column>
			<el-table-column prop="prompt" label="提示词" width="250" header-align="center" align="center"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="200" header-align="center" align="center"></el-table-column>
			<el-table-column label="操作" header-align="left" align="left">
				<template slot-scope="scope">
					<el-button @click="deleteItem(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
			<template slot="empty">
				<div style="text-align: center">
					<el-empty description="没有数据">
						<el-button type="primary" style="width: 200px" @click="startCreate()">+ 新建</el-button>
					</el-empty>
				</div>
			</template>
		</el-table>
		<el-pagination
			class="pagination"
			background
			layout="total, sizes, prev, pager, next, jumper"
			:total="total"
			:current-page="currentPage"
			:page-sizes="[5, 10, 20, 30, 40]"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
		></el-pagination>
	</div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { getLastRecord, deleteRecord } from '@/utils/api';

export default {
	components: {},
	mounted() {
		this.loadLastRecord(1);
	},
	data() {
		return {
			tableData: [],
			total: 1,
			currentPage: 1,
			pageSize: 10
		};
	},
	methods: {
		startCreate() {
			window.open('/#/Dashboard/Draw', '_self', '', true);
		},
		loadLastRecord(pageNo, pageSize) {
			var params = {
				pageNo: pageNo,
				pageSize: pageSize
			};
			getLastRecord(params).then((res) => {
				console.log('getLastRecord=====' + JSON.stringify(res));
				this.recordCount = res.data.total;
				this.tableData = res.data.content;
				this.total = res.data.total;
			});
		},
		handleSizeChange(newSize) {
			this.pageSize = newSize;
			this.loadLastRecord(1, newSize);
		},
		handleCurrentChange(newPage) {
			this.currentPage = newPage;
			this.loadLastRecord(newPage, this.pageSize);
		},
		deleteItem(id) {
			this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				deleteRecord(id).then((res) => {
					console.log('deleteRecord=====' + JSON.stringify(res));
					this.loadLastRecord(this.currentPage, this.pageSize);
				});
			});
		}
	}
};
</script>

<style lang="less">
.main-content {
	width: 100%;
	height: calc(100vh - 80px);
	padding: 15px;
	background-color: #f6f7fa;
	
	.pagination {
		margin-top: 20px;
	}
}
</style>
