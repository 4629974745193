<template>
	<div class="main">
		<div v-show="!srcImg || srcImg.trim() == ''" class="pre-box">
			<div class="pre-title">智能抠图</div>
			<div class="pre-desc">全品类智能识别，自动精准抠取主体，提供专业抠图编辑，轻松实现图片的放大、缩小及旋转</div>
			<div class="pre-box-upload">
				<el-image src="https://oss.djsmart.vip/bed/a001.png"></el-image>
				<div class="upload-box">
					<el-upload :on-success="uploadSuccess" :show-file-list="false" :multiple="false" :action="uploadUrl" style="width: 100%; height: 80px">
						<el-button type="primary" style="width: 150px; height: 50px; margin-top: 120px; font-size: 16px">
							<i class="el-icon-upload el-icon--left"></i>
							上传图片
						</el-button>
					</el-upload>
					<div class="prepare-upload-tips">
						<div>点击上传图片到此处</div>
						<div>支持JPG、JPEG、PNG、WEBP格式</div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="srcImg && srcImg.trim() !== ''" class="image-box" :style="'width:' + boxW + 'px;height:' + boxH + 'px'" ref="maskBox" @mousedown="onmousedownHandle">
			<img :src="maskImg" alt="" :style="{ width: realWidth + 'px', height: realHeight + 'px', top: top + 'px', left: left + 'px', transform: scale }" />
			<div class="edit-image">
				<i class="el-icon-zoom-in icon" @click="imgScaleHandle(0.125)"></i>
				<i class="el-icon-zoom-out icon" @click="imgScaleHandle(-0.125)"></i>
				<i class="el-icon-refresh-left icon" @click="handleRotate(-45)"></i>
				<i class="el-icon-refresh-right icon" @click="handleRotate(45)"></i>
			</div>
			<div class="edit-reset" @click="handleReset">重置</div>
			<div class="edit-btn" @click="openMattingEdit">编辑抠图</div>
			<div class="down-mask-btn" @click="downloadMattingImage">下载</div>
		</div>
		<MattingEdit :mattingEditOpen.sync="mattingEditOpen" :srcImg="srcImg" :maskImg="maskImg" @dataFromMatting="mattingMaskImg" />
	</div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { rmbg, generateMattingImage } from '@/utils/api';
import MattingEdit from '@/components/MattingEdit';
export default {
	components: {
		MattingEdit
	},
	data() {
		return {
			mattingEditOpen: false,
			uploadUrl: axios.defaults.baseURL + '/file/ua/upload',
			//srcImg: 'https://oss.djsmart.vip/bed/bed018.jpg',
			//maskImg: 'https://oss.djsmart.vip/bed/mask018.png',
			srcImg: '',
			maskImg: '',
			boxViewW: 1024,
			boxViewH: 1024,
			boxW: 680,
			boxH: 680,
			imgW: 500,
			imgH: 500,
			deg: 0,
			top: 0,
			left: 0,
			anchorX: 0,
			anchorY: 0,
			scale: 'scale(1)',
			size: 0,
			maskRatio: 1,
			mouseOn: false,
			realWidth: 500,
			realHeight: 500
		};
	},
	mounted() {
		//初始化图片
		this.initImage();
	},
	methods: {
		downloadMattingImage() {
			var ratioValue = this.boxViewW / this.boxW;
			var params = {
				maskImg: this.maskImg,
				boxViewW: this.boxViewW,
				boxViewH: this.boxViewH,
				scale: this.size * ratioValue,
				rotate: this.deg,
				x: Math.floor((this.left - this.anchorX) * ratioValue),
				y: Math.floor((this.top - this.anchorY) * ratioValue)
			};
			const loading = this.$loading({
				lock: true,
				text: '正在处理中…',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			generateMattingImage(params).then((res) => {
				console.log('generateMattingImage=====' + JSON.stringify(res));
				if (res.data.drawResult === 0) {
					this.downloadImage(res.data.generateImg, new Date().toISOString().replace(/\D/g, '') + '.png')
				}
				if (res.data.drawResult === 1) {
					this.$alert(res.data.drawResultDesc, '提示信息', {
						confirmButtonText: '确定'
					});
				}
				loading.close();
			});
		},
		downloadImage(imageUrl, imageName) {
		  // 使用fetch API获取图片
		  fetch(imageUrl)
		    .then(response => {
		      // 确保响应是成功的
		      if (!response.ok) {
		        throw new Error('Network response was not ok.');
		      }
		      // 将响应体转换为Blob
		      return response.blob();
		    })
		    .then(blob => {
		      // 创建一个链接元素
		      const a = document.createElement('a');
		      // 创建一个指向Blob的URL
		      a.href = window.URL.createObjectURL(blob);
		      // 设置下载属性和文件名
		      a.download = imageName || 'downloaded-image';
		      // 隐藏链接元素
		      a.style.display = 'none';
		      // 将链接元素添加到文档中
		      document.body.appendChild(a);
		      // 模拟点击链接
		      a.click();
		      // 释放Blob URL
		      window.URL.revokeObjectURL(a.href);
		      // 从文档中移除链接元素
		      document.body.removeChild(a);
		    })
		    .catch(error => {
		      console.error('There was a problem with the fetch operation:', error);
		    });
		},
		proportionSelect(value) {
			console.log('==value==' + value);
			if (value == 1) {
				this.boxW = 680;
				this.boxH = 680;
				this.boxViewW = 1024;
				this.boxViewH = 1024;
			}
			if (value == 2) {
				this.boxW = 680;
				this.boxH = 510;
				this.boxViewW = 1024;
				this.boxViewH = 768;
			}
			if (value == 3) {
				this.boxW = 510;
				this.boxH = 680;
				this.boxViewW = 768;
				this.boxViewH = 1024;
			}
			if (value == 4) {
				this.boxW = 680;
				this.boxH = 382;
				this.boxViewW = 1024;
				this.boxViewH = 576;
			}
			if (value == 5) {
				this.boxW = 382;
				this.boxH = 680;
				this.boxViewW = 576;
				this.boxViewH = 1024;
			}
			this.initImage();
		},
		mattingMaskImg(data) {
			console.log('==mattingMaskImg==' + data);
			this.maskImg = data;
			this.initImage();
		},
		openMattingEdit() {
			this.mattingEditOpen = true;
		},
		uploadSuccess(res, file) {
			const loading = this.$loading({
				lock: true,
				text: '正在处理中…',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			rmbg({ imgUrl: res.data }).then((res) => {
				this.srcImg = res.data.srcImg;
				this.maskImg = res.data.generateImg;
				loading.close();
				this.initImage();
			});
		},
		/**
		 * 获取图片的url
		 * @param {string} url
		 */
		getImgSize(url) {
			return new Promise((resolve, reject) => {
				let imgObj = new Image();
				imgObj.src = url;
				imgObj.onload = () => {
					resolve({
						width: imgObj.width,
						height: imgObj.height
					});
				};
			});
		},
		/**
		 * 初始化图片
		 */
		async initImage() {
			if (!this.maskImg) {
				return;
			}
			let { width, height } = await this.getImgSize(this.maskImg);
			// 设置原始图片的大小
			let realWidth = width;
			let realHeight = height;
			this.realWidth = realWidth;
			this.realHeight = realHeight;

			console.log('==realWidth==' + realWidth);
			console.log('==realHeight==' + realHeight);

			//获取盒子的大小
			const boxW = this.boxW;
			const boxH = this.boxH;

			console.log('==boxW==' + boxW);
			console.log('==boxH==' + boxH);

			//宽高都小于盒子，直接放入盒子
			if (realWidth <= boxW && realHeight <= boxH) {
				this.imgW = realWidth;
				this.imgH = realHeight;
				this.setImgPosition(boxW, boxH);
				return;
			}
			//宽大于盒子，高小于盒子
			if (realWidth >= boxW && realHeight <= boxH) {
				var ratio = boxW / realWidth;
				this.imgW = realWidth * ratio;
				this.imgH = realHeight * ratio;
				this.setImgPosition(boxW, boxH);
				this.maskRatio = ratio;
				return;
			}
			//宽小于盒子，高大于盒子
			if (realWidth <= boxW && realHeight >= boxH) {
				var ratio = boxH / realHeight;
				this.imgW = realWidth * ratio;
				this.imgH = realHeight * ratio;
				this.setImgPosition(boxW, boxH);
				this.maskRatio = ratio;
				return;
			}
			//宽高大都大于盒子
			if (realWidth >= boxW && realHeight >= boxH) {
				var ratioW = boxW / realWidth;
				var ratioH = boxH / realHeight;
				//取缩放更多的进行缩放
				var ratio = Math.min(ratioW, ratioH);
				this.imgW = realWidth * ratio;
				this.imgH = realHeight * ratio;
				this.setImgPosition(boxW, boxH);
				this.maskRatio = ratio;
				return;
			}
		},
		setImgPosition(boxW, boxH) {
			this.top = (boxH - this.realHeight) / 2;
			this.left = (boxW - this.realWidth) / 2;
			this.size = this.imgW / this.realWidth;
			this.anchorX = this.left;
			this.anchorY = this.top;
			this.imgScaleHandle(0);
		},
		/**
		 * 旋转
		 */
		handleRotate(value) {
			this.deg += value;
			this.scale = `scale(${this.size}) rotateZ(${this.deg}deg)`;
		},

		/**
		 * 图片的缩放
		 *    zoom >0 放大
		 *    zoom <0 缩小
		 */
		imgScaleHandle(zoom) {
			this.size += zoom;
			if (this.size < -0.5) {
				this.size = -0.5;
			}
			this.scale = `scale(${this.size}) rotateZ(${this.deg}deg)`;
			console.log('===scale===' + this.scale);
			console.log('===this.size===' + this.size * (this.boxViewW / this.boxW));
			console.log('==that.left==' + (this.left + 620) * 1.5);
			console.log('==that.top==' + (this.top + 160) * 1.5);
		},

		/**
		 * 重置
		 */
		handleReset() {
			this.imgW = 0;
			this.imgH = 0;
			this.top = 0;
			this.left = 0;
			this.deg = 0;
			this.scale = 'scale(1)';
			this.size = 0;
			this.initImage();
		},

		/**
		 * 处理图片拖动
		 */
		onmousedownHandle(e) {
			const that = this;
			this.$refs.maskBox.onmousemove = function (el) {
				const ev = el || window.event; // 阻止默认事件
				ev.preventDefault();
				that.left += ev.movementX;
				that.top += ev.movementY;
			};
			this.$refs.maskBox.onmouseup = function () {
				// 鼠标抬起时将操作区域的鼠标按下和抬起事件置为null 并初始化
				that.$refs.maskBox.onmousemove = null;
				that.$refs.maskBox.onmouseup = null;

				console.log('==that.left==' + (that.left + 620) * 1.5);
				console.log('==that.top==' + (that.top + 160) * 1.5);
			};
			if (e.preventDefault) {
				e.preventDefault();
			} else {
				return false;
			}
		}
	}
};
</script>
<style lang="less">
@import '@/views/draw/AIMatting.less';
</style>
