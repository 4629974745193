<template>
	<div class="main">
		<div class="left">
			<div class="proportion">
				画面比例
				<el-select class="proportion-select" v-model="proportionType" @change="proportionSelect" placeholder="自定义">
					<el-option label="1:1" value="1"></el-option>
					<el-option label="4:3" value="2"></el-option>
					<el-option label="3:4" value="3"></el-option>
					<el-option label="16:9" value="4"></el-option>
					<el-option label="9:16" value="5"></el-option>
				</el-select>
			</div>
			<div class="proportion-value">
				<el-input style="margin: 0 10px" :placeholder="boxViewW + ' px 宽'"></el-input>
				X
				<el-input style="margin: 0 10px" :placeholder="boxViewH + ' px 高'"></el-input>
			</div>
			<div class="goods-pic-title">商品图</div>
			<div class="goods-pic-container">
				<div class="upload-box">
					<div v-if="srcImg && srcImg.trim() !== ''">
						<el-image style="padding: 10px; width: 220px; height: 130px" fit="contain" :src="srcImg"></el-image>
						<div class="upload-edit">
							<el-upload class="upload-edit-left" :on-success="uploadSuccess" :show-file-list="false" :multiple="false" :action="uploadUrl">重新上传</el-upload>
							<div class="upload-edit-right" @click="openMattingEdit">编辑抠图</div>
						</div>
					</div>
					<el-upload v-else :on-success="uploadSuccess" :show-file-list="false" :multiple="false" :action="uploadUrl" style="width: 100%">
						<i style="font-size: 24px" class="el-icon-upload2"></i>
						<div>上传商品图</div>
					</el-upload>
				</div>
			</div>
			<div class="refrence-pic-title">参考图</div>
			<div class="refrence-pic-container">
				<div class="refrence-box">
					<div v-if="bgImg && bgImg.trim() !== ''" class="refrence-img-container" @mouseenter="showReplaceBgImg(true)" @mouseleave="showReplaceBgImg(false)">
						<el-image style="width: 66px; height: 66px; margin: 10px 0 0 10px; border-radius: 5px; cursor: pointer" :src="bgImg"></el-image>
						<el-upload v-if="mouseOn" :on-success="bgImgUploadSuccess" :show-file-list="false" :multiple="false" :action="uploadUrl" class="bg-img-replace">
							<i style="font-size: 24px; color: #fff; margin-top: 10px" class="el-icon-upload2"></i>
							<div style="font-size: 12px; color: #fff">替换图片</div>
						</el-upload>
					</div>
					<el-upload v-else class="refrence-upload-box" :on-success="bgImgUploadSuccess" :show-file-list="false" :multiple="false" :action="uploadUrl">
						<i style="font-size: 24px" class="el-icon-upload2"></i>
						<div style="font-size: 12px">上传图片</div>
					</el-upload>

					<div class="tips-box" @click="selectBgImg">
						<div style="color: #333940; font-size: 14px; text-align: left">或从资源库选择</div>
						<div style="color: #090f207f; font-size: 12px; text-align: left">提供多种风格与主题的背景图素材</div>
					</div>
					<div class="arrow-container">
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
			</div>
			<div class="number-title">生成张数</div>
			<div class="number-container">
				<div :class="'number-box ' + cssSelect(item)" v-for="(item, index) in [1, 2, 3, 4]" @click="numberSelect(item)">
					{{ item }}
				</div>
			</div>
			<el-button type="primary" style="width: 235px; height: 60px; margin-top: 50px; font-size: 20px" @click="startCreat">开始生成</el-button>
		</div>
		<div class="right">
			<div v-show="!srcImg || srcImg.trim() == ''" class="prepare-container">
				<div class="prepare-title">三步完成商品图换背景</div>
				<div class="prepare-step">
					<div class="step-container">
						<div class="step1-box">
							<el-image src="https://oss.djsmart.vip/20241205/20241205210555367441494.png" class="step-img"></el-image>
						</div>
						<div class="step-title">
							上传商品图
							<br />
							(自动抠图)
						</div>
					</div>
					<div class="step-line"></div>
					<div class="step-container">
						<el-image src="https://oss.djsmart.vip/background/bg005.jpg" class="step-img"></el-image>
						<div class="step-title">上传或选择背景图</div>
					</div>
					<div class="step-line"></div>
					<div class="step-container">
						<el-image src="https://oss.djsmart.vip/20241205/20241205210909641436784.png" class="step-img"></el-image>
						<div class="step-title">点击生成</div>
					</div>
				</div>
				<div class="prepare-upload-container">
					<div class="prepare-upload-box">
						<div style="width: 100%">
							<el-upload :on-success="uploadSuccess" :show-file-list="false" :multiple="false" :action="uploadUrl">
								<el-button type="primary" style="width: 150px; height: 50px; margin-top: 50px; font-size: 16px">
									<i class="el-icon-upload el-icon--left"></i>
									上传商品图
								</el-button>
							</el-upload>
							<div class="prepare-upload-tips">
								<div>点击上传图片到此处</div>
								<div>支持JPG、JPEG、PNG、WEBP格式</div>
							</div>
							<div class="try-title">
								<div class="line"></div>
								<div>没有图片和想法？试试这些</div>
								<div class="line"></div>
							</div>
							<div class="try-img-container">
								<el-image
									v-for="item in exampleImgs"
									style="width: 80px; height: 80px; margin: 10px; border-radius: 5px; cursor: pointer"
									:src="item"
									@click="useExampleImg(item)"
								></el-image>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-show="srcImg && srcImg.trim() !== ''" class="right-view-container">
				<div class="image-box" :style="'width:' + boxW + 'px;height:' + boxH + 'px'" ref="maskBox" @mousedown="onmousedownHandle">
					<img :src="maskImg" alt="" :style="{ width: realWidth + 'px', height: realHeight + 'px', top: top + 'px', left: left + 'px', transform: scale }" />
					<div class="edit-image">
						<i class="el-icon-zoom-in icon" @click="imgScaleHandle(0.125)"></i>
						<i class="el-icon-zoom-out icon" @click="imgScaleHandle(-0.125)"></i>
						<i class="el-icon-refresh-left icon" @click="handleRotate(-45)"></i>
						<i class="el-icon-refresh-right icon" @click="handleRotate(45)"></i>
					</div>
					<div class="edit-reset" @click="handleReset">重置</div>
					<div class="edit-btn" @click="openMattingEdit">编辑抠图</div>
				</div>
				<div v-show="resultImgs.length > 0 || resultImgBoxs.length > 0" class="result-box">
					<div v-for="(item, index) in resultImgs" class="result-item-box">
						<el-image fit="contain" :src="item.generateImg" @mouseenter="setResultMaskValue(index)"></el-image>
						<div v-if="showResultMask(index)" class="result-img-mask" @mouseleave="setResultMaskValue(-1)">
							<div style="text-align: right; margin: 5px 10px 0px 0px">
								<i class="el-icon-download" style="cursor: pointer" @click="downLoadResultImg(item.generateImg)"></i>
								<i class="el-icon-delete" style="margin-left: 10px; cursor: pointer" @click="deleteResultImg(item.id)"></i>
							</div>
							<div style="justify-content: center; display: flex; align-items: center; height: 220px">
								<div style="cursor: pointer" @click="imgDetailOpen(item.id)">
									<i class="el-icon-view" style="margin-right: 5px"></i>
									图片详情
								</div>
							</div>
						</div>
					</div>
					<div v-for="item in resultImgBoxs" class="result-pre-box">
						<i class="el-icon-loading"></i>
					</div>
				</div>
			</div>
		</div>
		<MattingEdit :mattingEditOpen.sync="mattingEditOpen" :srcImg="srcImg" :maskImg="maskImg" @dataFromMatting="mattingMaskImg" />
		<BgImgDialog :bgImgDialogOpen.sync="bgImgDialogOpen" @dataFromBgImg="bgImgSelect" />
		<BgReplaceResultDialog :bgReplaceDialogOpen.sync="bgReplaceDialogOpen" :goodsImgId="goodsImgId" />
	</div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { rmbg, drawWithMask, deleteGoodsById, getGoodsById } from '@/utils/api';
import MattingEdit from '@/components/MattingEdit';
import BgImgDialog from '@/components/BgImgDialog';
import BgReplaceResultDialog from '@/components/BgReplaceResultDialog';
export default {
	components: {
		MattingEdit,
		BgImgDialog,
		BgReplaceResultDialog
	},
	data() {
		return {
			mattingEditOpen: false,
			bgImgDialogOpen: false,
			bgReplaceDialogOpen: false,
			proportionType: null,
			selectItem: 1,
			uploadUrl: axios.defaults.baseURL + '/file/ua/upload',
			//srcImg: 'https://oss.djsmart.vip/bed/bed018.jpg',
			//maskImg: 'https://oss.djsmart.vip/bed/mask018.png',
			//bgImg: 'https://oss.djsmart.vip/background/bg028.jpg',
			srcImg: '',
			maskImg: '',
			bgImg: '',
			exampleImgs: [
				'https://oss.djsmart.vip/app/goods/g005.jpg',
				'https://oss.djsmart.vip/app/goods/g004.jpg',
				'https://oss.djsmart.vip/app/goods/g003.jpg',
				'https://oss.djsmart.vip/app/goods/g002.jpg',
				'https://oss.djsmart.vip/app/goods/g001.jpg'
			],
			boxViewW: 1024,
			boxViewH: 1024,
			boxW: 680,
			boxH: 680,
			imgW: 500,
			imgH: 500,
			deg: 0,
			top: 0,
			left: 0,
			anchorX: 0,
			anchorY: 0,
			scale: 'scale(1)',
			size: 0,
			maskRatio: 1,
			mouseOn: false,
			realWidth: 500,
			realHeight: 500,
			resultImgs: [],
			resultImgBoxs: [],
			goodsImgId: 0,
			showMaskValue: -1
		};
	},
	mounted() {
		//初始化图片
		this.initImage();
	},
	methods: {
		deleteResultImg(id) {
			deleteGoodsById(id).then((res) => {
				this.resultImgs = this.resultImgs.filter((item) => item.id !== id);
			});
		},
		downLoadResultImg(imgUrl) {
			const x = new window.XMLHttpRequest();
			x.open('GET', imgUrl, true);
			x.responseType = 'blob';
			x.onload = () => {
				const url = window.URL.createObjectURL(x.response);
				const a = document.createElement('a');
				a.href = url;
				a.download = imgUrl.substring(imgUrl.lastIndexOf('/') + 1);
				a.click();
			};
			x.send();
		},
		imgDetailOpen(id) {
			this.bgReplaceDialogOpen = true;
			this.goodsImgId = id;
		},
		setResultMaskValue(value) {
			this.showMaskValue = value;
		},
		showResultMask(value) {
			return this.showMaskValue == value;
		},
		useExampleImg(imgUrl){
			const loading = this.$loading({
				lock: true,
				text: '正在处理中…',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			rmbg({ imgUrl: imgUrl }).then((res) => {
				this.srcImg = res.data.srcImg;
				this.maskImg = res.data.generateImg;
				loading.close();
				this.initImage();
			});
			
		},
		startCreat() {
			this.resultImgBoxs = [];
			this.resultImgs = [];
			var ratioValue = this.boxViewW / this.boxW;
			for (let n = 0; n < this.selectItem; n++) {
				console.log(`这是第 ${n + 1} 次循环`);
				var params = {
					bgImg: this.bgImg,
					maskImg: this.maskImg,
					boxViewW: this.boxViewW,
					boxViewH: this.boxViewH,
					scale: this.size * ratioValue,
					rotate: this.deg,
					x: Math.floor((this.left - this.anchorX) * ratioValue),
					y: Math.floor((this.top - this.anchorY) * ratioValue)
				};
				this.resultImgBoxs.push(n);
				drawWithMask(params).then((res) => {
					console.log('drawWithMask=====' + JSON.stringify(res));
					if (res.data.drawResult === 0) {
						this.resultImgs.push(res.data);
						this.resultImgBoxs.pop();
					}
					if (res.data.drawResult === 1) {
						this.$alert(res.data.drawResultDesc, '提示信息', {
							confirmButtonText: '确定'
						});
					}
				});
			}
		},
		downloadImage(base64Image) {
			// 创建一个临时的a标签
			const link = document.createElement('a');
			// 设置下载的图片文件名
			link.download = 'downloaded-image.png';
			// 设置a标签的href属性为Base64编码的图片数据
			link.href = base64Image;
			// 将a标签添加到文档中
			document.body.appendChild(link);
			// 触发点击事件
			link.click();
			// 移除a标签
			document.body.removeChild(link);
		},
		showReplaceBgImg(show) {
			if (show && this.bgImg && this.bgImg.trim() !== '') {
				this.mouseOn = true;
			} else {
				this.mouseOn = false;
			}
		},
		selectBgImg() {
			this.bgImgDialogOpen = true;
		},
		proportionSelect(value) {
			console.log('==value==' + value);
			if (value == 1) {
				this.boxW = 680;
				this.boxH = 680;
				this.boxViewW = 1024;
				this.boxViewH = 1024;
			}
			if (value == 2) {
				this.boxW = 680;
				this.boxH = 510;
				this.boxViewW = 1024;
				this.boxViewH = 768;
			}
			if (value == 3) {
				this.boxW = 510;
				this.boxH = 680;
				this.boxViewW = 768;
				this.boxViewH = 1024;
			}
			if (value == 4) {
				this.boxW = 680;
				this.boxH = 382;
				this.boxViewW = 1024;
				this.boxViewH = 576;
			}
			if (value == 5) {
				this.boxW = 382;
				this.boxH = 680;
				this.boxViewW = 576;
				this.boxViewH = 1024;
			}
			this.initImage();
		},
		mattingMaskImg(data) {
			console.log('==mattingMaskImg==' + data);
			this.maskImg = data;
			this.initImage();
		},
		cssSelect(item, index) {
			if (this.selectItem === item) {
				return 'number-select';
			}
			return '';
		},
		numberSelect(item) {
			this.selectItem = item;
		},
		openMattingEdit() {
			this.mattingEditOpen = true;
		},
		uploadSuccess(res, file) {
			const loading = this.$loading({
				lock: true,
				text: '正在处理中…',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			rmbg({ imgUrl: res.data }).then((res) => {
				this.srcImg = res.data.srcImg;
				this.maskImg = res.data.generateImg;
				loading.close();
				this.initImage();
			});
		},
		bgImgUploadSuccess(res, file) {
			this.bgImg = res.data;
		},
		bgImgSelect(value) {
			this.bgImg = value;
		},
		/**
		 * 获取图片的url
		 * @param {string} url
		 */
		getImgSize(url) {
			return new Promise((resolve, reject) => {
				let imgObj = new Image();
				imgObj.src = url;
				imgObj.onload = () => {
					resolve({
						width: imgObj.width,
						height: imgObj.height
					});
				};
			});
		},
		/**
		 * 初始化图片
		 */
		async initImage() {
			if (!this.maskImg) {
				return;
			}
			let { width, height } = await this.getImgSize(this.maskImg);
			// 设置原始图片的大小
			let realWidth = width;
			let realHeight = height;
			this.realWidth = realWidth;
			this.realHeight = realHeight;

			console.log('==realWidth==' + realWidth);
			console.log('==realHeight==' + realHeight);

			//获取盒子的大小
			const boxW = this.boxW;
			const boxH = this.boxH;

			console.log('==boxW==' + boxW);
			console.log('==boxH==' + boxH);

			//宽高都小于盒子，直接放入盒子
			if (realWidth <= boxW && realHeight <= boxH) {
				this.imgW = realWidth;
				this.imgH = realHeight;
				this.setImgPosition(boxW, boxH);
				return;
			}
			//宽大于盒子，高小于盒子
			if (realWidth >= boxW && realHeight <= boxH) {
				var ratio = boxW / realWidth;
				this.imgW = realWidth * ratio;
				this.imgH = realHeight * ratio;
				this.setImgPosition(boxW, boxH);
				this.maskRatio = ratio;
				return;
			}
			//宽小于盒子，高大于盒子
			if (realWidth <= boxW && realHeight >= boxH) {
				var ratio = boxH / realHeight;
				this.imgW = realWidth * ratio;
				this.imgH = realHeight * ratio;
				this.setImgPosition(boxW, boxH);
				this.maskRatio = ratio;
				return;
			}
			//宽高大都大于盒子
			if (realWidth >= boxW && realHeight >= boxH) {
				var ratioW = boxW / realWidth;
				var ratioH = boxH / realHeight;
				//取缩放更多的进行缩放
				var ratio = Math.min(ratioW, ratioH);
				this.imgW = realWidth * ratio;
				this.imgH = realHeight * ratio;
				this.setImgPosition(boxW, boxH);
				this.maskRatio = ratio;
				return;
			}
		},
		setImgPosition(boxW, boxH) {
			this.top = (boxH - this.realHeight) / 2;
			this.left = (boxW - this.realWidth) / 2;
			this.size = this.imgW / this.realWidth;
			this.anchorX = this.left;
			this.anchorY = this.top;
			this.imgScaleHandle(0);
		},
		/**
		 * 旋转
		 */
		handleRotate(value) {
			this.deg += value;
			this.scale = `scale(${this.size}) rotateZ(${this.deg}deg)`;
		},

		/**
		 * 图片的缩放
		 *    zoom >0 放大
		 *    zoom <0 缩小
		 */
		imgScaleHandle(zoom) {
			this.size += zoom;
			if (this.size < -0.5) {
				this.size = -0.5;
			}
			this.scale = `scale(${this.size}) rotateZ(${this.deg}deg)`;
			console.log('===scale===' + this.scale);
			console.log('===this.size===' + this.size * (this.boxViewW / this.boxW));
			console.log('==that.left==' + (this.left + 620) * 1.5);
			console.log('==that.top==' + (this.top + 160) * 1.5);
		},

		/**
		 * 重置
		 */
		handleReset() {
			this.imgW = 0;
			this.imgH = 0;
			this.top = 0;
			this.left = 0;
			this.deg = 0;
			this.scale = 'scale(1)';
			this.size = 0;
			this.initImage();
		},

		/**
		 * 鼠标滚动 实现放大缩小
		 */
		wheelHandle(e) {
			const ev = e || window.event; // 兼容性处理 => 火狐浏览器判断滚轮的方向是属性 detail，谷歌和ie浏览器判断滚轮滚动的方向是属性 wheelDelta
			// dir = -dir; // dir > 0 => 表示的滚轮是向上滚动，否则是向下滚动 => 范围 (-120 ~ 120)
			const dir = ev.detail ? ev.detail * -120 : ev.wheelDelta;
			//滚动的数值 / 2000 => 表示滚动的比例，用此比例作为图片缩放的比例
			//this.imgScaleHandle(dir / 2000);
		},

		/**
		 * 处理图片拖动
		 */
		onmousedownHandle(e) {
			const that = this;
			this.$refs.maskBox.onmousemove = function (el) {
				const ev = el || window.event; // 阻止默认事件
				ev.preventDefault();
				that.left += ev.movementX;
				that.top += ev.movementY;
			};
			this.$refs.maskBox.onmouseup = function () {
				// 鼠标抬起时将操作区域的鼠标按下和抬起事件置为null 并初始化
				that.$refs.maskBox.onmousemove = null;
				that.$refs.maskBox.onmouseup = null;

				console.log('==that.left==' + (that.left + 620) * 1.5);
				console.log('==that.top==' + (that.top + 160) * 1.5);
			};
			if (e.preventDefault) {
				e.preventDefault();
			} else {
				return false;
			}
		}
	}
};
</script>
<style lang="less">
@import '@/views/draw/GoodsReplace.less';
</style>
