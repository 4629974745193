<template>
	<el-dialog top="10vh" @open="handleOpen" :before-close="handleClose" :visible.sync="mattingEditOpen" :closeOnClickModal="false" width="1240px">
		<div class="opt-title">
			<el-button size="medium" :type="maskOptType === 1 ? 'primary' : ''" icon="el-icon-edit" @click="maskOptTypeSwitch(1)">画笔</el-button>
			<el-button size="medium" :type="maskOptType === 0 ? 'primary' : ''" icon="el-icon-delete" @click="maskOptTypeSwitch(0)">橡皮擦</el-button>
			<el-button size="medium" :type="maskOptType === 2 ? 'primary' : ''" icon="el-icon-androidhand" @click="maskOptTypeSwitch(2)">抓手</el-button>
			<div class="vetical-line"></div>
			<el-button size="medium" icon="el-icon-refresh-left" @click="undo()">撤消</el-button>
			<el-button size="medium" icon="el-icon-refresh-right" @click="redo()">恢复</el-button>
			<el-button size="medium" icon="el-icon-refresh" @click="reset()">重置</el-button>
			<div class="change-size-container">
				<i class="el-icon-zoom-out" style="cursor: pointer" @click="changeSize(-1)"></i>
				<div style="width: 60px; text-align: center">{{ viewRatio }}%</div>
				<i class="el-icon-zoom-in" style="cursor: pointer" @click="changeSize(1)"></i>
			</div>
			<el-button style="position: absolute; right: 150px" @click="handleClose">取消修改</el-button>
			<el-button style="position: absolute; right: 30px" type="primary" @click="mattingEditComplete">完成修改</el-button>
		</div>
		<div class="opt-size">
			<el-slider
				:min="5"
				v-if="maskOptType === 1 || maskOptType === 2 ? true : false"
				style="width: 160px; margin-left: 20px"
				v-model="penSizeView"
				@change="optSizeChange"
			></el-slider>
			<el-slider :min="5" v-if="maskOptType === 0 ? true : false" style="width: 160px; margin-left: 120px" v-model="penSizeView" @change="optSizeChange"></el-slider>
		</div>
		<div class="main-view">
			<div class="left-view">
				<div class="canvas-container">
					<canvas class="canvas1" ref="canvas1" :width="srcImgW" :height="srcImgH" :style="elementStyle"></canvas>
					<canvas
						:style="elementStyle2"
						class="canvas2"
						ref="canvas2"
						:width="srcImgW"
						:height="srcImgH"
						@mousedown="startDrawing"
						@mousemove="draw"
						@mouseup="stopDrawing"
						@mouseleave="stopDrawing"
						@mouseout="stopDrawing"
					></canvas>
				</div>
			</div>
			<div class="right-view">
				<div class="opacity-container">
					<div class="opacity-bg"></div>
					<canvas :style="elementStyle" ref="canvas3" class="canvas3" :width="srcImgW" :height="srcImgH"></canvas>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import { saveBase64Image } from '@/utils/api';

export default {
	components: {},
	props: {
		srcImg: '',
		maskImg: '',
		mattingEditOpen: false
	},
	data() {
		return {
			drawing: false,
			maskOptType: 1, //蒙板操作类型 0=橡皮擦 1=画笔
			lastX: 0,
			lastY: 0,
			penSizeView: 50,
			penSizeRadius: 25,
			context1: null,
			context2: null,
			context3: null,
			penImage: '',
			undoStack: [],
			redoStack: [],
			srcImgW: 560,
			srcImgH: 560,
			ratioImgW: 560,
			ratioImgH: 560,
			left: 0,
			top: 0,
			changeRatio: 1,
			tongsSwitch: false,
			scale: 1, // 初始缩放比例
			isDragging: false, // 是否正在拖动
			clickX: 0, // 拖动时的偏移量
			clickY: 0,
			positionX: 0, // 元素的当前位置
			positionY: 0,
			viewRatio: 100,
			dialogKey: 0
		};
	},
	computed: {
		elementStyle() {
			return {
				transform: `scale(${this.scale})`,
				left: `${this.positionX}px`,
				top: `${this.positionY}px`
			};
		},
		elementStyle2() {
			var cursorShap = this.maskOptType == 2 ? 'pointer' : `url(${this.penImage}) ${this.penSizeRadius} ${this.penSizeRadius}, auto`;
			return {
				transform: `scale(${this.scale})`,
				left: `${this.positionX}px`,
				top: `${this.positionY}px`,
				cursor: cursorShap
			};
		}
	},
	mounted() {
		this.initMattingImg();
	},
	methods: {
		initMattingImg() {
			console.log('==initMattingImg==');
			var img = new Image();
			img.crossOrigin = 'Anonymous';
			img.src = this.srcImg;
			var that = this;
			img.onload = function () {
				that.srcImgW = img.width;
				that.srcImgH = img.height;
			};
		},
		// 开始拖动
		startDrag(event) {
			this.isDragging = true;
			this.clickX = event.clientX;
			this.clickY = event.clientY;
		},
		// 停止拖动
		stopDrag() {
			this.isDragging = false;
		},
		// 拖动中
		drag(event) {
			if (this.isDragging) {
				var dx = event.clientX - this.clickX;
				var dy = event.clientY - this.clickY;
				this.positionX += dx;
				this.positionY += dy;
				this.clickX = event.clientX;
				this.clickY = event.clientY;
			}
		},

		changeSize(value) {
			if (value > 0) {
				if (this.scale >= 10) {
					return;
				}
				this.scale += 0.1;
				this.viewRatio += 10;
			} else {
				if (this.scale <= 0.1) {
					return;
				}
				this.scale -= 0.1;
				this.viewRatio -= 10;
			}
			this.getPenImage();
		},
		downloadImage(base64Image) {
			// 创建一个临时的a标签
			const link = document.createElement('a');
			// 设置下载的图片文件名
			link.download = 'downloaded-image.png';
			// 设置a标签的href属性为Base64编码的图片数据
			link.href = base64Image;
			// 将a标签添加到文档中
			document.body.appendChild(link);
			// 触发点击事件
			link.click();
			// 移除a标签
			document.body.removeChild(link);
		},
		optSizeChange(e) {
			this.getPenImage();
		},
		handleOpen() {
			this.$nextTick(() => {
				this.initMattingImg();
				this.resetParams();
				var img = new Image();
				img.crossOrigin = 'Anonymous';
				img.src = this.srcImg;
				var that = this;
				img.onload = function () {
					// 设置原始图片的大小
					let realWidth = img.width;
					let realHeight = img.height;

					var boxW = 560;
					var boxH = 560;

					//宽大于盒子，高小于盒子
					if (realWidth >= boxW && realHeight <= boxH) {
						that.changeRatio = boxW / realWidth;
					}
					//宽小于盒子，高大于盒子
					if (realWidth <= boxW && realHeight >= boxH) {
						that.changeRatio = boxH / realHeight;
					}
					//宽高大都大于盒子
					if (realWidth >= boxW && realHeight >= boxH) {
						var ratioW = boxW / realWidth;
						var ratioH = boxH / realHeight;
						//取缩放更多的进行缩放
						that.changeRatio = Math.min(ratioW, ratioH);
					}

					that.context1 = that.$refs.canvas1.getContext('2d');
					that.context1.drawImage(img, 0, 0, realWidth, realHeight);

					that.context3 = that.$refs.canvas3.getContext('2d');
					var ctx3 = that.context3;
					ctx3.drawImage(that.$refs.canvas1, 0, 0, realWidth, realHeight);

					that.drawMaskImage(0, 0, realWidth, realHeight);
					that.positionX += (boxW - realWidth) / 2;
					that.positionY += (boxH - realHeight) / 2;
					that.scale = that.changeRatio;
					that.getPenImage();
				};
			});
		},
		drawMaskImage(left, top, imgW, imgH) {
			this.context2 = this.$refs.canvas2.getContext('2d');
			var ctx2 = this.context2;

			// 设置矩形的填充样式为半透明
			ctx2.fillStyle = '#3F0AD2'; // 蓝色半透明（50%）

			// 绘制矩形
			ctx2.fillRect(left, top, imgW, imgH); // x, y, width, height

			// 加载蒙板图片
			var maskImage = new Image();
			maskImage.src = this.maskImg; // 替换为你的蒙板图片路径
			maskImage.crossOrigin = 'Anonymous';
			var that = this;
			maskImage.onload = function () {
				ctx2.globalCompositeOperation = 'destination-in'; // 设置组合操作
				ctx2.drawImage(maskImage, left, top, imgW, imgH);
				ctx2.globalCompositeOperation = 'source-over'; // 恢复默认组合操作
				that.drawMask2View();
			};
		},
		handleClose() {
			this.reset();
			this.$emit('update:mattingEditOpen', false);
		},
		mattingEditComplete() {
			const loading = this.$loading({
				lock: true,
				text: '正在处理中…',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			var that = this;
			saveBase64Image({ base64Img: this.$refs.canvas3.toDataURL('image/png') }).then((res) => {
				that.$emit('dataFromMatting', res.data);
				that.handleClose();
				loading.close();
			});
		},
		maskOptTypeSwitch(type) {
			this.maskOptType = type;
			this.tongsSwitch = type == 2;
		},
		saveState() {
			this.undoStack.push(this.$refs.canvas2.toDataURL());
			this.redoStack = []; // 清空redo栈，因为新操作后无法恢复之前的状态
		},
		undo() {
			var canvas = this.context2;
			var ctx = this.$refs.canvas2;
			if (this.undoStack.length > 0) {
				var prevState = this.undoStack.pop();
				this.redoStack.push(ctx.toDataURL()); // 保存当前状态以便redo
				var that = this;
				var img = new Image();
				img.src = prevState;
				img.onload = function () {
					canvas.clearRect(0, 0, ctx.width, ctx.height);
					canvas.drawImage(img, 0, 0);
					that.drawMask2View();
				};
			}
		},
		redo() {
			var canvas = this.context2;
			var ctx = this.$refs.canvas2;
			if (this.redoStack.length > 0) {
				var nextState = this.redoStack.pop();
				this.undoStack.push(ctx.toDataURL()); // 保存当前状态以便undo
				var that = this;
				var img = new Image();
				img.src = nextState;
				img.onload = function () {
					canvas.clearRect(0, 0, ctx.width, ctx.height);
					canvas.drawImage(img, 0, 0);
					that.drawMask2View();
				};
			}
		},
		reset() {
			this.resetParams();
			this.handleOpen();
		},
		resetParams() {
			this.positionX = 0;
			this.positionY = 0;
			this.scale = 1;
			this.viewRatio = 100;
		},
		startDrawing(e) {
			if (this.tongsSwitch) {
				this.startDrag(e);
				return;
			}
			this.saveState();
			this.drawing = true;
			[this.lastX, this.lastY] = [e.offsetX + this.penSizeView / 2, e.offsetY + this.penSizeView / 2];
			this.draw(e);
		},
		draw(e) {
			if (this.tongsSwitch) {
				this.drag(e);
				return;
			}
			if (!this.drawing) return;
			const ctx2 = this.context2;

			// 设置线条的宽度，端点和连接点为圆角
			ctx2.lineWidth = this.penSizeView;
			ctx2.strokeStyle = this.penColorView;
			ctx2.lineCap = 'round';
			ctx2.lineJoin = 'round';

			let cursorDist = this.penSizeView / 2;
			let offsetX = e.offsetX + cursorDist;
			let offsetY = e.offsetY + cursorDist;

			// 计算从上一个点到当前点的距离
			let distX = offsetX - this.lastX;
			let distY = offsetY - this.lastY;
			let distance = Math.sqrt(distX * distX + distY * distY);

			// 计算平滑的步数
			let steps = Math.ceil(distance / 4);
			// 递增地绘制线条
			for (let i = 0; i <= steps; i++) {
				// 计算插值点的位置
				let t = i / steps;
				let x = this.lastX + distX * (t ? t : 0);
				let y = this.lastY + distY * (t ? t : 0);
				x -= cursorDist;
				y -= cursorDist;

				// 移动到起始点
				ctx2.beginPath();
				ctx2.moveTo(x, y);
				if (this.maskOptType === 1) {
					this.drawCircular(ctx2, x, y, cursorDist);
				}
				if (this.maskOptType === 0) {
					this.cleanCircular(ctx2, x, y, cursorDist);
				}
			}

			// 更新最后的位置
			[this.lastX, this.lastY] = [offsetX, offsetY];
		},
		stopDrawing() {
			if (this.tongsSwitch) {
				this.stopDrag();
				return;
			}
			if (this.drawing) {
				this.drawing = false;
				this.drawMask2View();
			}
		},
		drawMask2View() {
			var ctx3 = this.context3;
			ctx3.drawImage(this.$refs.canvas1, 0, 0);

			ctx3.globalCompositeOperation = 'destination-in'; // 设置组合操作
			ctx3.drawImage(this.$refs.canvas2, 0, 0);
			ctx3.globalCompositeOperation = 'source-over'; // 恢复默认组合操作
		},
		drawCircular(ctx, x, y, radius) {
			radius -= 2;
			// 设置矩形的填充样式为半透明
			ctx.fillStyle = '#603AA5'; // 蓝色半透明（50%）
			ctx.arc(x, y, radius, 0, 2 * Math.PI);
			ctx.fill();
		},
		cleanCircular(ctx, x, y, radius) {
			radius -= 2;
			ctx.save();
			ctx.globalCompositeOperation = 'destination-out';
			ctx.beginPath();
			ctx.arc(x, y, radius, 0, Math.PI * 2, false);
			ctx.fill();
			ctx.restore();
		},
		getPenImage() {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			var size = this.penSizeView * this.scale;
			canvas.width = size;
			canvas.height = size;

			// 绘制一个圆点
			const centerX = size / 2;
			const centerY = size / 2;
			const radius = size / 2 - 1; // 圆点半径

			ctx.beginPath();
			ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);

			ctx.fillStyle = '#603AA5'; // 圆点颜色
			ctx.fill();
			// 将canvas内容转换为base64编码的字符串
			this.penImage = canvas.toDataURL('image/png');
			this.penSizeRadius = radius;
		}
	}
};
</script>
<style lang="less" scoped>
.el-dialog__header {
	display: none; /* 完全隐藏标题栏 */
}
.opt-title {
	margin: -10px 20px 10px 20px;
	display: flex;
	text-align: left;
	align-items: center;
	justify-content: left;
	.vetical-line {
		width: 1px;
		height: 30px;
		margin-left: 15px;
		margin-right: 15px;
		border-right: #f1f2f3 solid 2px;
	}
	.change-size-container {
		margin-left: 40px;
		padding-left: 5px;
		padding-right: 5px;
		background-color: #85888c;
		border-radius: 5px;
		width: 110px;
		height: 30px;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		font-size: 16px;
		color: #fff;
	}
}
.opt-size {
	display: flex;
	margin-top: -10px;
}
.main-view {
	display: flex;

	.left-view {
		width: 600px;
		height: 600px;
		position: relative;
		border: #ccc 1px solid;
		overflow: hidden;
		.canvas-container {
			margin-left: 20px;
			margin-top: 20px;
			display: flex;
			position: relative;
			.canvas1 {
				position: absolute;
				z-index: 1; /* 设置堆叠顺序 */
			}
			.canvas2 {
				position: absolute;
				opacity: 0.5; /* 半透明效果 */
				z-index: 2; /* 设置堆叠顺序，比div1高 */
			}
		}
	}
	.right-view {
		width: 600px;
		height: 600px;
		position: relative;
		border: #ccc 1px solid;
		overflow: hidden;
		.opacity-container {
			margin-left: 20px;
			margin-top: 20px;
			display: flex;
			position: relative;
		}
		.opacity-bg {
			width: 560px;
			height: 560px;
			background-image: linear-gradient(45deg, #e3e7e9 25%, transparent 25%, transparent 75%, #e3e7e9 75%),
				linear-gradient(45deg, #e3e7e9 25%, transparent 25%, transparent 75%, #e3e7e9 75%);
			background-size: 20px 20px;
			background-position: 0 0, 10px 10px;

			position: absolute;
			z-index: 1; /* 设置堆叠顺序 */
		}
		.canvas3 {
			position: absolute;
			z-index: 2; /* 设置堆叠顺序 */
		}
	}
}
</style>
