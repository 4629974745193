<template>
	<el-dialog @open="handleOpen" :before-close="handleClose" :visible.sync="bgImgDialogOpen" title="选择背景图" style="margin-top: -10vh">
		<div class="bg-img-container">
			<el-upload class="upload-container" :on-success="uploadSuccess" :show-file-list="false" :multiple="false" :action="uploadUrl">
				<i style="font-size: 24px" class="el-icon-upload2"></i>
				<div style="font-size: 12px">上传图片</div>
			</el-upload>
			<el-image class="bg-img" :src="item.imgUrl" v-for="item in bgImgs" style="width: 120px; height: 120px;cursor: pointer;" @click="bgImgSelect(item.imgUrl)"/>
		</div>
	</el-dialog>
</template>

<script>
import axios from 'axios';
import { listBackgroundImg } from '@/utils/api';
export default {
	data() {
		return {
			bgImgs: [],
			uploadUrl: axios.defaults.baseURL + '/file/ua/upload'
		};
	},
	props: {
		bgImgDialogOpen: false
	},
	mounted() {},
	methods: {
		uploadSuccess(res, file) {
			this.$emit('dataFromBgImg', res.data);
			this.handleClose();
		},
		bgImgSelect(imgUrl){
			this.$emit('dataFromBgImg', imgUrl);
			this.handleClose();
		},
		handleOpen() {
			listBackgroundImg().then((res) => {
				this.bgImgs = res.data;
			});
		},
		handleClose() {
			this.$emit('update:bgImgDialogOpen', false);
		},
		selectImage(index) {
			this.currentImageIndex = index;
		}
	}
};
</script>

<style lang="less" scoped>
.bg-img-container {
	display: flex;
	flex-wrap: wrap;
	.upload-container {
		width: 118px;
		height: 118px;
		margin: 5px;
		border: 1px dashed #ccdbff;
		border-radius: 10px;
		background-color: #f5f9ff;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		color: #0076fe;
		cursor: pointer;
	}
	.bg-img {
		border-radius: 10px;
		margin: 5px;
	}
}
</style>
