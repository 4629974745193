import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/Home.vue'
import AboutView from '@/views/about/About.vue'
import DashboardView from '@/views/Dashboard.vue'
import DrawView from '@/views/draw/Draw.vue'
import GoodsReplaceView from '@/views/draw/GoodsReplace.vue'
import AIMattingView from '@/views/draw/AIMatting.vue'
import MyworkView from '@/views/mywork/Mywork.vue'


Vue.use(VueRouter)

const routes = [{
		path: "/",
		name: "home",
		meta: {
			title: "AI修图-AI画图-AI穿衣-高效AI图片处理必备工具-AI图像大师",
			keepAlive: false, // 需要缓存
		},
		component: HomeView,
	},
	{
		path: "/About",
		name: "about",
		meta: {
			title: "关于AI图像大师-AI图像大师",
			keepAlive: false, // 需要缓存
		},
		component: AboutView,
	},
	{
		path: "/Dashboard",
		name: "Dashboard",
		meta: {
			title: "控制台-AI图像大师",
			keepAlive: false, // 需要缓存
		},
		component: DashboardView,
		children: [{
				path: "GoodsReplace",
				name: "GoodsReplace",
				meta: {
					title: "一键换背景-AI图像大师",
					keepAlive: false, // 需要缓存
				},
				component: GoodsReplaceView,
			},
			{
				path: "AIMatting",
				name: "AIMatting",
				meta: {
					title: "智能抠图-AI图像大师",
					keepAlive: false, // 需要缓存
				},
				component: AIMattingView,
			},
			{
				path: "Draw",
				name: "Draw",
				meta: {
					title: "线稿图上色-AI图像大师",
					keepAlive: false, // 需要缓存
				},
				component: DrawView,
			},
			{
				path: "Mywork",
				name: "Mywork",
				meta: {
					title: "创作中心-我的作品",
					keepAlive: false, // 需要缓存
				},
				component: MyworkView,
			}
		]
	}

];

const router = new VueRouter({
	linkActiveClass: 'active', //路由默认样式
	routes
})

//beforeEach 函数有三个参数：
//to:router即将进入的路由对象
//from:当前导航即将离开的路由
//next: Function,进行管道中的一个钩子，如果执行完了，则导航的状态就是confirmed (确认的)
//afterEach 函数 不用传next()函数
router.beforeEach(function(to, from, next) {
	if (_hmt) {
		if (to.path) {
			_hmt.push(['_trackPageview', '/#' + to.fullPath]);
		}
	}
	document.title = to.meta.title || 'AI图像大师';
	next();
})

export default router