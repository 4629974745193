<template>
	<div class="page-box">
		<section>
			<div class="main-box">
				<div class="draw-box" ref="drawBox">
					<canvas
						:style="'cursor: url(' + penImage + '), auto;'"
						ref="myCanvas"
						:width="canvasWidth"
						:height="canvasHeight"
						@mousedown="startDrawing"
						@mousemove="draw"
						@mouseup="stopDrawing"
						@mouseleave="stopDrawing"
					></canvas>
				</div>
				<div class="tool-box">
					<div style="margin-top: 15px; margin-left: -40px">画笔大小</div>
					<div class="pen-size">
						<div v-for="n in 9" :class="'pen-size-common' + penSelect(indexKey, n)" @click="selectPenSize(n)">
							<div :style="'width: ' + n * 5 + 'px;height: 40px;background-color: ' + penSelectColor(indexKey, n) + ';blur(1px);'"></div>
						</div>
					</div>
					<div style="margin-left: -40px">画笔颜色</div>
					<div class="color-box">
						<div
							:class="'color-item' + colorSelect(item.code)"
							v-for="item in strokeStyleVos"
							:style="'background-color: ' + item.code + ';'"
							@click="selectPenColor(item.code)"
						/>
					</div>
					<el-button type="primary" style="width: 80%; margin-top: 10px" @click="descOpen()">下一步</el-button>
				</div>
			</div>
		</section>
		<el-dialog :visible.sync="dialogVisible" width="400px">
			<div class="popup-content">
				<div class="templateContentTitle">模板图<span style="color: red;">(非必选)</span></div>
				<div class="uploadView">
					<el-upload class="uploadViewContent" v-if="showUpload" :on-success="uploadInsImg" :show-file-list="false" :multiple="false" :action="uploadUrl">
						<div class="addImg">
							<img src="@/assets/images/addImg.png" />
						</div>
						<div class="uploadText">点击上传模板图</div>
						<div class="uploadTips">支持png、jpg格式，15M以内</div>
					</el-upload>
					<div class="uploadViewImg" v-else>
						<el-image class="inspirationImg" fit="contain" :src="inspirationImg"></el-image>
						<div class="reUploadText" @click="reUpload">重置</div>
					</div>
				</div>
				<div class="inspiration">
					<div class="title">没有灵感?试试这些</div>
					<el-image v-for="item in inspirations" :key="item.imgUrl" :src="item.imgUrl" @click="selectInspiration(item.imgUrl)"></el-image>
				</div>
				<div class="splitLine"></div>
				<div class="ratioContentTitle">画布比例</div>
				<div class="ratioContent" v-if="inspirationImg === ''">
					<div :class="'ratioView ' + selectClass(insKey, 1, 16 / 9) + ''" @click="selectRatio(1)">
						<div class="ratioHeight">
							<div class="ratioText" style="width: 36px; height: 64px"></div>
						</div>
						<div class="ratioNumber">9:16</div>
					</div>
					<div :class="'ratioView ' + selectClass(insKey, 2, 4 / 3) + ''" @click="selectRatio(2)">
						<div class="ratioHeight">
							<div class="ratioText" style="width: 36px; height: 48px"></div>
						</div>
						<div class="ratioNumber">3:4</div>
					</div>
					<div :class="'ratioView ' + selectClass(insKey, 3, 1) + ''" @click="selectRatio(3)">
						<div class="ratioHeight">
							<div class="ratioText" style="width: 36px; height: 36px"></div>
						</div>
						<div class="ratioNumber">1:1</div>
					</div>
					<div :class="'ratioView ' + selectClass(insKey, 4, 3 / 4) + ''" @click="selectRatio(4)">
						<div class="ratioHeight">
							<div class="ratioText" style="width: 48px; height: 36px"></div>
						</div>
						<div class="ratioNumber">4:3</div>
					</div>
					<div :class="'ratioView ' + selectClass(insKey, 5, 9 / 16) + ''" @click="selectRatio(5)">
						<div class="ratioHeight">
							<div class="ratioText" style="width: 64px; height: 36px"></div>
						</div>
						<div class="ratioNumber">16:9</div>
					</div>
				</div>
				<div class="ratioContent" v-else>
					<div class="ratioView selected">
						<div class="ratioHeight">
							<div class="ratioText" :style="'width: ' + imgWidthRatio + 'px; height: ' + imgHeightRatio + 'px'"></div>
						</div>
						<div class="ratioNumber">{{ imgWidthRatio }}:{{ imgHeightRatio }}</div>
					</div>
				</div>
				<div class="btnPopup">
					<el-button type="primary" @click="startCreating">开始创作</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="descDialogVisible" width="460px" style="margin-top: -5vh">
			<div class="desc-dialog">
				<div class="src-img-title">原图:</div>
				<el-image class="canvas-img" fit="contain" :src="canvasImg" />
				<div class="prompt-title">作品描术:</div>
				<el-input class="prompt-txt" type="textarea" :rows="4" placeholder="以短句、短语为佳,支持中英文输入.也可以不填,让AI自己发挥." v-model="prompt"></el-input>
				<el-button class="start-btn" type="primary" @click="drawStart">开始AI生成</el-button>
			</div>
		</el-dialog>
		<ImageViewDialog :imgDialogVisible.sync="imageDialogOpen" :images="viewImages"/>
	</div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { loginUserInfo, loginOut, getStrokeBaseColor, saveBase64Image, img2img, txt2img } from '@/utils/api';
import ImageViewDialog from '@/components/ImageViewDialog';

export default {
	components: {
		ImageViewDialog
	},
	mounted() {
		this.loadUserInfo();
		this.context = this.$refs.myCanvas.getContext('2d');
		this.getPenImage();
		this.selectPenSize(3);
		this.getStrokeStyle();
		this.getInspirations();
	},
	data() {
		return {
			userInfo: {},
			drawing: false,
			context: null,
			lastX: 0,
			lastY: 0,
			canvasWidth: 1600,
			canvasHeight: 800,
			penImage: '',
			indexKey: 3,
			penSizeView: 5,
			penColorView: '#000000',
			strokeStyleVos: [],
			dialogVisible: true,
			descDialogVisible: false,
			imageDialogOpen: false,
			showUpload: true,
			inspirationImg: '',
			inspirations: [],
			imgWidthRatio: 1,
			imgHeightRatio: 1,
			insKey: 3,
			uploadUrl: axios.defaults.baseURL + '/file/ua/upload',
			canvasImg: '',
			prompt: '',
			viewImages: []
		};
	},
	methods: {

		goDashboard() {
			window.open('/#/Dashboard', '_self', '', true);
		},
		drawStart() {
			var params = {
				imgUrl: this.canvasImg,
				samplerName: 'DPM++ 2M',
				steps: 20,
				prompt: this.prompt
			};
			const loading = this.$loading({
				lock: true,
				text: 'AI拼命画图中……',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			txt2img(params).then((res) => {
				console.log('==txt2img res==' + JSON.stringify(res));
				if (res.data.drawResult === 0) {
					this.viewImages = [res.data.generateImg, res.data.srcImg];
					this.imageDialogOpen = true;
				}
				if (res.data.drawResult === 1) {
					this.$alert(res.data.drawResultDesc, '提示信息', {
						confirmButtonText: '确定'
					});
				}

				loading.close();
			});
			setTimeout(() => {
				loading.close();
			}, 15000);
		},
		descOpen() {
			const base64Img = this.$refs.myCanvas.toDataURL('image/png');
			console.log('===base64Img===' + base64Img);
			saveBase64Image({ base64Img: base64Img }).then((res) => {
				console.log('==imgUrl==' + res.data);
				this.canvasImg = res.data;
				this.descDialogVisible = true;
			});
		},
		selectInspiration(imgUrl) {
			this.inspirationImg = imgUrl;
			var img = new Image();
			img.crossOrigin = 'Anonymous';
			img.src = imgUrl;
			var that = this;
			img.onload = function () {
				var width = img.naturalWidth;
				var height = img.naturalHeight;
				console.log('图片尺寸: ' + width + ' x ' + height);
				let ratio = 1;
				let maxLength = 64;
				if (width > height) {
					ratio = maxLength / width;
				} else {
					ratio = maxLength / height;
				}
				that.imgWidthRatio = Math.round(width * ratio);
				that.imgHeightRatio = Math.round(height * ratio);

				//设置画布大小
				const drawBoxDiv = that.$refs.drawBox;
				const boxWidth = drawBoxDiv.clientWidth * 0.9;
				const boxHeight = drawBoxDiv.clientHeight * 0.9;
				that.canvasWidth = Math.min(boxWidth, width);
				that.canvasHeight = Math.min(boxHeight, height);
			};
			this.showUpload = false;
		},
		getInspirations() {
			this.inspirations = [
				{ imgUrl: 'https://oss.djsmart.vip/app/src1.jpg' },
				{ imgUrl: 'https://oss.djsmart.vip/app/src2.jpg' },
				{ imgUrl: 'https://oss.djsmart.vip/app/src3.jpg' }
			];
		},
		startCreating() {
			this.dialogVisible = false;
			console.log('==this.inspirationImg==' + this.inspirationImg);
			if (this.inspirationImg != '') {
				var img = new Image();
				img.crossOrigin = 'Anonymous';
				img.src = this.inspirationImg;
				var that = this;
				img.onload = function () {
					let width = img.naturalWidth;
					let height = img.naturalHeight;

					const drawBoxDiv = that.$refs.drawBox;
					const boxWidth = drawBoxDiv.clientWidth * 0.9;
					const boxHeight = drawBoxDiv.clientHeight * 0.9;
					var cWidth = Math.min(boxWidth, width);
					var cHeight = Math.min(boxHeight, height);
					that.context.drawImage(img, 0, 0, width, height, 0, 0, cWidth, cHeight);
				};
			}
		},
		selectRatio(insKey) {
			this.insKey = insKey;
		},
		selectClass(insKey, index, ratio) {
			if (insKey === index) {
				const drawBoxDiv = this.$refs.drawBox;
				if (drawBoxDiv) {
					const width = drawBoxDiv.clientWidth; // 或使用 offsetWidth 来包含边框和滚动条
					const height = drawBoxDiv.clientHeight; // 或使用 offsetHeight 来包含边框和滚动条
					console.log(`Div的宽度是: ${width}px, 高度是:${height}px`);
					// 这里你可以根据需要进行其他操作，比如将尺寸绑定到数据属性上
					var maxWidth = width * 0.9;
					var maxHeight = height * 0.9;
					var boxRatio = maxWidth / maxHeight;

					//长宽比大于容器长宽比，宽度等比缩小
					if (ratio > boxRatio) {
						this.canvasHeight = maxWidth / ratio;
						this.canvasWidth = maxWidth;
					} else {
						this.canvasWidth = maxHeight / ratio;
						this.canvasHeight = maxHeight;
					}
					console.log(`画布的宽度是: ${this.canvasWidth}px, 高度是:${this.canvasHeight}px`);
				}
				return 'selected';
			}
			return '';
		},
		reUpload() {
			this.showUpload = true;
			this.inspirationImg = '';
		},
		uploadInsImg(res, file) {
			this.selectInspiration(res.data);
		},
		colorSelect(colorCode) {
			if (colorCode === this.penColorView) {
				return ' select';
			} else {
				return '';
			}
		},
		getStrokeStyle() {
			getStrokeBaseColor().then((res) => {
				this.strokeStyleVos = res.data;
			});
		},
		selectPenColor(colorCode) {
			console.log('==colorCode==' + colorCode);
			this.penColorView = colorCode;
			this.getPenImage();
		},
		selectPenSize(n) {
			this.indexKey = n;
			this.penSizeView = n * 5;
			this.getPenImage();
		},
		penSelect(indexKey, n) {
			if (indexKey === n) {
				return ' select';
			} else {
				return '';
			}
		},
		penSelectColor(indexKey, n) {
			if (indexKey === n) {
				return '#000000';
			} else {
				return '#777777';
			}
		},
		getPenImage() {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			var size = this.penSizeView;
			console.log('====size====' + size);
			canvas.width = size;
			canvas.height = size;

			// 绘制一个圆点
			const centerX = size / 2;
			const centerY = size / 2;
			const radius = size / 2 - 2; // 圆点半径

			ctx.beginPath();
			ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
			ctx.fillStyle = this.penColorView; // 圆点颜色
			ctx.filter = `blur(1px)`;
			ctx.fill();
			// 将canvas内容转换为base64编码的字符串
			this.penImage = canvas.toDataURL('image/png');
		},
		startDrawing(e) {
			this.drawing = true;
			[this.lastX, this.lastY] = [e.offsetX + this.penSizeView / 2, e.offsetY + this.penSizeView / 2];
			this.draw(e);
		},
		draw(e) {
			if (!this.drawing) return;
			const ctx = this.context;

			// 设置线条的宽度，端点和连接点为圆角
			ctx.lineWidth = this.penSizeView;
			ctx.strokeStyle = this.penColorView;
			ctx.lineCap = 'round';
			ctx.lineJoin = 'round';

			let cursorDist = this.penSizeView / 2;
			let offsetX = e.offsetX + cursorDist;
			let offsetY = e.offsetY + cursorDist;

			// 计算从上一个点到当前点的距离
			let distX = offsetX - this.lastX;
			let distY = offsetY - this.lastY;
			let distance = Math.sqrt(distX * distX + distY * distY);

			// 计算平滑的步数
			let steps = Math.ceil(distance / 4);

			// 递增地绘制线条
			for (let i = 0; i <= steps; i++) {
				// 计算插值点的位置
				let t = i / steps;
				let x = this.lastX + distX * t;
				let y = this.lastY + distY * t;

				if (i === 0) {
					// 移动到起始点
					ctx.beginPath();
					ctx.moveTo(x, y);
				} else {
					// 绘制到插值点
					ctx.lineTo(x, y);
				}
			}
			// 实际绘制线条
			ctx.moveTo(this.lastX, this.lastY);
			ctx.lineTo(offsetX, offsetY);
			ctx.stroke();

			// 更新最后的位置
			[this.lastX, this.lastY] = [offsetX, offsetY];
		},
		stopDrawing() {
			this.drawing = false;
		},
		loadUserInfo() {
			loginUserInfo().then((res) => {
				this.userInfo = res.data;
			});
		},
		openDrawDialog() {},
		dropdownClick(command) {
			console.log('command=====' + command);
			if (command === 'loginOut') {
				loginOut().then((res) => {
					console.log('loginOut=====' + JSON.stringify(res));
					Cookies.remove('token');
				});
				setTimeout(() => {
					this.$router.push({ path: '/' });
				}, 500);
			}
		}
	}
};
</script>

<style lang="less">
@import '@/views/draw/Draw.less';
</style>
