<template>
	<el-container>
		<el-aside style="width: 250px; height: calc(100vh - 20px)">
			<img @click="goHome()" style="cursor: pointer" src="@/assets/logo-big.png" alt="logo" class="logo" />
			<el-menu active-text-color="#067BEF" :default-active="defaultActive" @open="handleOpen" @close="handleClose">
				<el-menu-item index="/Dashboard/GoodsReplace" @click="openUrl('/Dashboard/GoodsReplace')">
					<i class="el-icon-picture-outline"></i>
					<span slot="title">商品图换背景</span>
				</el-menu-item>
				<el-menu-item index="/Dashboard/AIMatting" @click="openUrl('/Dashboard/AIMatting')">
					<i class="el-icon-picture-outline-round"></i>
					<span slot="title">智能抠图</span>
				</el-menu-item>
				<el-menu-item index="/Dashboard/Draw" @click="openUrl('/Dashboard/Draw')">
					<i class="el-icon-s-open"></i>
					<span slot="title">涂鸦作画</span>
				</el-menu-item>
				<el-menu-item index="/Dashboard/Mywork" @click="openUrl('/Dashboard/Mywork')">
					<i class="el-icon-time"></i>
					<span slot="title">我的作品</span>
				</el-menu-item>
			</el-menu>

			<!-- 固定在底部的元素 -->
			<div class="fixed-bottom">作品数：{{ recordCount }}</div>
		</el-aside>
		<el-main style="padding: 5px">
			<div class="user-profile">
				<span class="nickname">
					<el-dropdown @command="dropdownClick">
						<span class="el-dropdown-link">
							{{ userInfo.name }}
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</span>
				<img :src="userInfo.headImg" class="avatar" />
			</div>
			<div class="right-container">
				<router-view></router-view>
			</div>
		</el-main>
	</el-container>
</template>

<script>
import Cookies from 'js-cookie';
import { loginUserInfo, loginOut, getLastRecord } from '@/utils/api';

export default {
	created() {
		var routeUrl = this.$route.path;
		if(routeUrl == '/Dashboard'){
			routeUrl = '/Dashboard/GoodsReplace'
		}
		this.defaultActive = routeUrl; 
		this.openUrl(routeUrl);
	},
	data() {
		return {
			defaultActive: '/Dashboard/GoodsReplace',
			recordCount: 0,
			userInfo: {}
		};
	},
	mounted() {
		this.loadUserInfo();
		this.loadLastRecord(1, 1);
	},
	methods: {
		openUrl(routeUrl) {
			if (this.$route.path !== routeUrl) {
				this.$router.replace(routeUrl);
			}
		},
		goHome() {
			window.open('/', '_self', '', true);
		},
		dropdownClick(command) {
			console.log('command=====' + command);
			if (command === 'loginOut') {
				loginOut().then((res) => {
					console.log('loginOut=====' + JSON.stringify(res));
					Cookies.remove('token');
				});
				setTimeout(() => {
					this.$router.push({ path: '/' });
				}, 500);
			}
		},
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		loadUserInfo() {
			loginUserInfo().then((res) => {
				this.userInfo = res.data;
			});
		},
		loadLastRecord(pageNo, pageSize) {
			var params = {
				pageNo: pageNo,
				pageSize: pageSize
			};
			getLastRecord(params).then((res) => {
				this.recordCount = res.data.total;
			});
		}
	}
};
</script>

<style lang="less">
@import '@/views/Dashboard.less';
</style>
